<template>
  <v-container>
    <v-spacer>
      <v-form ref="forms" v-model="valid" lazy-validation>
        <v-col>
          <!-- seleccionamos una tabla -->
          <v-select
            v-model="nombre"
            :items="products"
            label="Seleecione una tabla"
            :rules="[(v) => !!v || 'Campo obligatorio']"
            item-text="name"
            item-value="id"
            return-object
          ></v-select>
          <!-- Termina seleccionamos una tabla -->
          <!-- Adjuntamos el excel para cargar -->
          <label
            >Seleccione el archivo
            <input
              type="file"
              id="file"
              ref="file"
              @change="handleFileUpload()"
            />
          </label>
          <!-- Termina seleccionamos una tabla -->
          <v-btn
            class="ma-2"
            :loading="cargar"
            :disabled="!selectData"
            color="secondary"
            @click="submitFile()"
            >Subir archivo</v-btn
          >
        </v-col>
      </v-form>
      <v-col>
        <!-- Tabla usuarios -->
        <v-data-table
          :headers="headers_usuarios"
          v-if="nombre.id == 'usuarios'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.height"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Altura"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla usuarios -->
        <!-- Tabla Alturas -->
        <v-data-table
          :headers="headers_alturas"
          v-if="nombre.id == 'alturas'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.height"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Altura"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla alturas -->
        <!-- Tabla complaints -->
        <v-data-table
          :headers="headers_complaints"
          v-if="nombre.id == 'complaints'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="70vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col>
                            <v-select
                              v-model="editedItem.client"
                              :items="complaintsClient"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Cliente"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-select
                              v-model="editedItem.product"
                              :items="complaintsProduct"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Producto"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.complaint"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Queja"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.status"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Estatus"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.created_at"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Fecha de creacion"
                              hint="Ejemplo: 2021-12-18"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla complaints -->
        <!-- Tabla Telas -->
        <v-data-table
          :headers="headers_telas"
          v-if="nombre.id == 'telas'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.sku"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Sku"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla Telas -->
        <!-- Tabla invoices -->
        <v-data-table
          :headers="headers_invoices"
          v-if="nombre.id == 'invoices'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="80vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="5">
                            <v-select
                              v-model="editedItem.producto"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              :items="invoicesProducts"
                              label="Producto"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="5">
                            <v-select
                              v-model="editedItem.cliente"
                              :items="invoicesClients"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Cliente"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-text-field
                              v-model="editedItem.quantity"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Cantidad"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.total"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Total"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.contribution"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Contribucion"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.total_contribution"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Contribucion total"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.created_at"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Fecha de creacion"
                              hint="Ejemplo: 2021-12-18"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.unit_price"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Precio de venta"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.margen"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Margen"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla Invoices -->
        <!-- Tabla Orders -->
        <v-data-table
          :headers="headers_orders"
          v-if="nombre.id == 'orders'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer>
                <div class="text-center">
                  <v-dialog v-model="dialogs" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Vaciar tabla
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Confirmar eliminación
                      </v-card-title>

                      <v-card-text>
                        ¿Realmente deseas eliminar todos los valores de la
                        tabla?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            dialogs = false;
                            deleteAll();
                          "
                        >
                          Si
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.client"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              :items="ordersClients"
                              label="Cliente"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.order_number"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Numero de orden"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.total"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Total"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.delivery_date"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Fecha de entrega"
                              hint="Ejemplo: 2021-12-18"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.ordered_at"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Fecha de orden"
                              hint="Ejemplo: 2021-12-18"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla Orders -->
        <!-- Tabla ShortNames -->
        <v-data-table
          :headers="headers_shortNames"
          v-if="nombre.id == 'shortNames'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla Short Names -->
                <!-- Tabla Groups -->
        <v-data-table
          :headers="headers_shortNames"
          v-if="nombre.id == 'groups'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla Groups -->
        <!-- Tabla Categories -->
        <v-data-table
          :headers="headers_categories"
          v-if="nombre.id == 'categories'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla categories -->
        <!-- Tabla Clients -->
        <v-data-table
          :headers="headers_clients"
          v-if="nombre.id == 'clients'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.code"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Codigo"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.active"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="¿Activo (1) Inactivo (0)?"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla clients -->
        <!-- Tabla Alturas -->
        <v-data-table
          :headers="headers_paintings"
          v-if="nombre.id == 'paintings'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.sku"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Sku"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla alturas -->
        <!-- Tabla HumanResources -->
        <v-data-table
          :headers="headers_human"
          v-if="nombre.id == 'human_resources'"
          :items="data"
          sort-by="calories"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.date"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Fecha"
                              hint="Ejemplo: 01/01/2020"
                              persistent-hint
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.ingresos"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Ingresos"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.bajas"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Bajas"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.faltas"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Faltas"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.vacaciones"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Vacaciones"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.incapacidad"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Incapacidad"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.accidentes"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Accidentes"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.semana"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Semana"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.quincena"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Quincena"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="save"
                      :disabled="!valid"
                    >
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions2="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla HumanResources -->
        <!-- Tabla OrderDetails -->
        <v-data-table
          :headers="headers_orderDetails"
          v-if="nombre.id == 'orderDetails'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer>
                <div class="text-center">
                  <v-dialog v-model="dialogs" width="500">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="red lighten-2"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        Vaciar tabla
                      </v-btn>
                    </template>

                    <v-card>
                      <v-card-title class="text-h5 grey lighten-2">
                        Confirmar eliminación
                      </v-card-title>

                      <v-card-text>
                        ¿Realmente deseas eliminar todos los valores de la
                        tabla?
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="
                            dialogs = false;
                            deleteAll();
                          "
                        >
                          Si
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div></v-spacer
              >
              <v-dialog v-model="dialog" max-width="70vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="8" md="3">
                            <v-text-field
                              v-model="editedItem.quantity"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Cantidad"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="3">
                            <v-text-field
                              v-model="editedItem.unit_price"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Precio/u"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="3">
                            <v-select
                              v-model="editedItem.orders"
                              :items="ordersAll"
                              label="Orden"
                              data-vv-name="select"
                              item-value="id"
                              item-text="order_number"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="8" md="3">
                            <v-text-field
                              v-model="editedItem.added_at"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Agregado"
                              hint="Ejemplo: 2021-12-18"
                              required
                            ></v-text-field> </v-col
                        ></v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.product"
                              :items="products_array"
                              label="Producto"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla OrderDetails -->
        <!-- Tabla Production -->
        <v-data-table
          :headers="headers_Production"
          v-if="nombre.id == 'production'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="70vw">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="8" md="4">
                            <v-select
                              v-model="editedItem.product"
                              :items="productionProducts"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Producto"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.quantity"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Cantidad"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.sale_price"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Precio venta"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.contribution"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Contribución"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.margen"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Margen"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.workforce"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Personal"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.accepted"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Aceptados"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.rejected"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Rechazados"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="8" md="4">
                            <v-text-field
                              v-model="editedItem.created_at"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Fecha de creacion"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla Production -->
        <!-- Tabla Productos -->
        <v-data-table
          :headers="headers_products"
          v-if="nombre.id == 'products'"
          :items="data"
          sort-by="usuarios"
          class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>TABLA DE REGISTROS</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Nuevo registro
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" v-model="valid" lazy-validation>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.sku"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Sku"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.name"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Nombre"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.category"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              :items="productCategories"
                              label="Categoria"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col> </v-row
                        ><v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.active"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="¿Activo (1) Inactivo (0)?"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.short_name"
                              :items="productShortName"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="ShortName"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.tela"
                              :items="productTela"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Tela"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.pintura"
                              :items="productPintura"
                              label="Pintura"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              data-vv-name="select"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.altura"
                              :items="productAltura"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              label="Altura"
                              data-vv-name="select"
                              item-value="id"
                              item-text="height"
                              required
                            />
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-select
                              v-model="editedItem.product_group"
                              :items="productGroup"
                              label="Grupo"
                              data-vv-name="select"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <v-select
                              v-model="editedItem.client"
                              :items="productClient"
                              label="Cliente"
                              data-vv-name="select"
                              :rules="[(v) => !!v || 'Campo obligatorio']"
                              item-value="id"
                              item-text="name"
                              required
                            />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Guardar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >¿Realmente desea eliminar este registro?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                      >Si</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
        <!-- Termina Tabla clients -->
      </v-col></v-spacer
    ></v-container
  >
</template>
<script>
import axios from "axios";
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    file: "",
    select: null,
    selectData: null,
    products: [],
    ordersAll: [],
    productCategories: [],
    productShortName: [],
    productTela: [],
    productPintura: [],
    complaintsClient: [],
    complaintsProduct: [],
    productAltura: [],
    productGroup: [],
    ordersClients: [],
    productionProducts: [],
    productClient: [],
    invoicesProducts: [],
    invoicesClients: [],
    nombre: "",
    valid: true,
    data: [],
    products_array: [],
    //rulesIngresos: [(v) => !!v || "Este campo es requerido"],
    cargar: false,
    //Encabezamos tabla Alturas
    headers_alturas: [
      { text: "Height", value: "height" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_usuarios: [
      { text: "Username", value: "username" },
      { text: "Name", value: "name" },
      { text: "Email", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_products: [
      { text: "Sku", value: "sku" },
      { text: "Name", value: "name" },
      { text: "Categoria", value: "category.name" },
      { text: "Activo", value: "active" },
      { text: "Short Name", value: "short_name.name" },
      { text: "Tela", value: "tela.name" },
      { text: "Pintura", value: "pintura.name" },
      { text: "Altura", value: "altura.height" },
      { text: "Cliente", value: "client.name" },
      { text: "Grupo", value: "product_group.name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_orderDetails: [
      { text: "Cantidad", value: "quantity" },
      { text: "Precio unitario", value: "unit_price" },
      { text: "Producto", value: "product.name" },
      { text: "Orden", value: "orders.order_number" },
      { text: "Agregado", value: "added_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_invoices: [
      { text: "cliente", value: "cliente.name" },
      { text: "Producto", value: "producto.name" },
      { text: "Cantidad", value: "quantity" },
      { text: "Total", value: "total" },
      { text: "Contribucion", value: "contribution" },
      { text: "Contribucion total", value: "total_contribution" },
      { text: "Fecha de creación", value: "created_at" },
      { text: "Precio de venta", value: "unit_price" },
      { text: "Margen", value: "margen" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_clients: [
      { text: "Codigo", value: "code" },
      { text: "Nombre", value: "name" },
      { text: "Activo", value: "active" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_Production: [
      { text: "Producto", value: "product.name" },
      { text: "Cantidad", value: "quantity" },
      { text: "Precio de venta", value: "sale_price" },
      { text: "Contribución", value: "contribution" },
      { text: "Margen", value: "margen" },
      { text: "Personal", value: "workforce" },
      { text: "Aceptados", value: "accepted" },
      { text: "Rechazados", value: "rejected" },
      { text: "Fecha de creación", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_orders: [
      { text: "Cliente", value: "client.name" },
      { text: "Numero de orden", value: "order_number" },
      { text: "Total", value: "total" },
      { text: "Fecha de entrega", value: "delivery_date" },
      { text: "Fecha de orden", value: "ordered_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_shortNames: [
      { text: "Nombre", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
     headers_groups: [
      { text: "Nombre", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_telas: [
      { text: "Sku", value: "sku" },
      { text: "Nombre", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_complaints: [
      { text: "Queja", value: "complaint" },
      { text: "Estatus", value: "status" },
      { text: "cliente", value: "client.name" },
      { text: "Producto", value: "product.name" },
      { text: "Fecha de creación", value: "created_at" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_paintings: [
      { text: "Sku", value: "sku" },
      { text: "Nombre", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headers_categories: [
      { text: "Name", value: "name" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    //Termina Encabezamos tabla Alturas
    //Encabezamos HumanResources
    headers_human: [
      { text: "Fecha", value: "date" },
      { text: "Ingresos", value: "ingresos" },
      { text: "Bajas", value: "bajas" },
      { text: "Faltas", value: "faltas" },
      { text: "Vacaciones", value: "vacaciones" },
      { text: "Incapacidad", value: "incapacidad" },
      { text: "Accidentes", value: "accidentes" },
      { text: "Semana", value: "semana" },
      { text: "Quincena", value: "quincena" },
      { text: "Actions", value: "actions2", sortable: false },
    ],
    //Termina Encabezamos HumanResources
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo registro" : "Editar registro";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    nombre(value) {
      //Dependiendo el elemento seleccionado, mostramos la tabla
      if (value.id == "usuarios") {
        this.obtenerUsuarios();
      }
      if (value.id == "alturas") {
        this.obtenerAlturas();
      }
      if (value.id == "orderDetails") {
        this.obtenerOrderDetails();
      }
      if (value.id == "categories") {
        this.obtenerCategories();
      }
      if (value.id == "clients") {
        this.obtenerClients();
      }
      if (value.id == "paintings") {
        this.obtenerPaintings();
      }
      if (value.id == "human_resources") {
        this.obtenerHumanResources();
      }
      if (value.id == "products") {
        this.obtenerProducts();
      }
      if (value.id == "shortNames") {
        this.obtenerShortNames();
      }
      if (value.id == "telas") {
        this.obtenerTelas();
      }
      if (value.id == "invoices") {
        this.obtenerInvoices();
      }
       if (value.id == "groups") {
        this.obtenerGroups();
      }
      if (value.id == "orders") {
        this.obtenerOrders();
      }
      if (value.id == "production") {
        this.obtenerProduction();
      }
      if (value.id == "complaints") {
        this.obtenerComplaints();
      }
      //Termina Dependiendo el elemento seleccionado, mostramos la tabla
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.data.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      if (this.nombre.id == "human_resources") {
        console.log("1");
        axios
          .post("/human_resource/delete", this.editedItem)
          .then(() => {
            this.obtenerHumanResources();
            this.closeDelete();
            console.log("2");
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "invoices") {
        axios
          .post("/invoice/delete", this.editedItem)
          .then(() => {
            this.obtenerInvoices();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "groups") {
        var $group_id = this.editedItem["id"];
        axios
          .delete("/groups/" + $group_id)
          .then(() => {
            this.obtenerGroups();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "orders") {
        axios
          .post("/orders/delete", this.editedItem)
          .then(() => {
            this.obtenerOrders();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "categories") {
        var $categorie_id = this.editedItem["id"];
        axios
          .delete("/categories/" + $categorie_id)
          .then(() => {
            this.obtenerCategories();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "shortNames") {
        var $shortNames_id = this.editedItem["id"];
        axios
          .delete("/shortNames/" + $shortNames_id)
          .then(() => {
            this.obtenerShortNames();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "alturas") {
        console.log(this.editedItem.id);
        var $altura_id = this.editedItem["id"];
        axios
          .delete("/alturas/" + $altura_id)
          .then((resp) => {
            this.obtenerAlturas();
            this.closeDelete();
            console.log(resp);
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "orderDetails") {
        var $orderD_id = this.editedItem["id"];
        axios
          .delete("/orderDetails/" + $orderD_id)
          .then(() => {
            this.obtenerOrderDetails();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "telas") {
        var $tela_id = this.editedItem["id"];
        axios
          .delete("/telas/" + $tela_id)
          .then(() => {
            this.obtenerTelas();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "paintings") {
        var $painting_id = this.editedItem["id"];
        axios
          .delete("/paintings/" + $painting_id)
          .then(() => {
            this.obtenerPaintings();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "clients") {
        var $client_id = this.editedItem["id"];
        axios
          .delete("/clients/" + $client_id)
          .then(() => {
            this.obtenerClients();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "products") {
        var $products_id = this.editedItem["id"];
        axios
          .delete("/products/" + $products_id)
          .then(() => {
            this.obtenerProducts();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "production") {
        axios
          .post("/daily_production/delete", this.editedItem)
          .then(() => {
            this.obtenerProduction();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "complaints") {
        axios
          .post("/complaints/delete", this.editedItem)
          .then(() => {
            this.obtenerComplaints();
            this.closeDelete();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteAll() {
      if (this.nombre.id == "orders") {
        axios
          .post("/orders/deleteAllOrders")
          .then(() => {
            this.obtenerOrders();
          })
          .catch((error) => {
            console.log(error);
          });
      }
      if (this.nombre.id == "orderDetails") {
        axios
          .post("/deleteAllOrderDetails")
          .then(() => {
            this.obtenerOrderDetails();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    save() {
      //si el formulario esta validado avanzamos
      if (this.$refs.form.validate()) {
        //Si escogimos editar entra
        if (this.editedIndex > -1) {
          if (this.nombre.id == "alturas") {
            axios
              .put("/alturas/update", this.editedItem)
              .then(() => {
                this.obtenerAlturas();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "telas") {
            axios
              .put("/telas/update", this.editedItem)
              .then(() => {
                this.obtenerTelas();
                console.log("2");
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "orderDetails") {
            console.log(this.editedItem);
            axios
              .put("/orderDetails/update", this.editedItem)
              .then(() => {
                this.obtenerOrderDetails();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "production") {
            console.log(this.editedItem);
            axios
              .put("/daily_production/edit", this.editedItem)
              .then(() => {
                this.obtenerProduction();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "shortNames") {
            axios
              .put("/shortNames/update", this.editedItem)
              .then(() => {
                this.obtenerShortNames();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "human_resources") {
            axios
              .post("/human_resource/update", this.editedItem)
              .then(() => {
                this.obtenerHumanResources();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "categories") {
            axios
              .put("/categories/update", this.editedItem)
              .then(() => {
                this.obtenerCategories();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "invoices") {
            console.log(this.editedItem);
            axios
              .put("/invoice/edit", this.editedItem)
              .then(() => {
                this.obtenerInvoices();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "groups") {
            axios
              .put("/groups/update", this.editedItem)
              .then(() => {
                this.obtenerGroups();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "complaints") {
            axios
              .post("/complaints/update", this.editedItem)
              .then(() => {
                this.obtenerComplaints();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "orders") {
            console.log(this.editedItem);
            axios
              .put("/orders/edit", this.editedItem)
              .then(() => {
                this.obtenerOrders();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "clients") {
            const $client_id = this.editedItem["id"];
            console.log(this.editedItem);

            axios
              .put("/clients/" + $client_id, this.editedItem)
              .then((resp) => {
                this.obtenerClients();
                console.log(resp.data);
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "paintings") {
            axios
              .put("/paintings/update", this.editedItem)
              .then(() => {
                this.obtenerPaintings();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "products") {
            console.log(this.editedItem);
            axios
              .put("/products/update", this.editedItem)
              .then(() => {
                this.obtenerProducts();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        } //De lo contrario si escogimos crear
        else {
          if (this.nombre.id == "human_resources") {
            axios
              .post("/human_resource/create", this.editedItem)
              .then(() => {
                this.obtenerHumanResources();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "complaints") {
            axios
              .post("/complaints/create", this.editedItem)
              .then(() => {
                this.obtenerComplaints();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "production") {
            axios
              .post("/daily_production/create", this.editedItem)
              .then(() => {
                this.obtenerProduction();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "alturas") {
            axios
              .post("/alturas", this.editedItem)
              .then(() => {
                this.obtenerAlturas();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "invoices") {
            axios
              .post("/invoice/create", this.editedItem)
              .then(() => {
                this.obtenerInvoices();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "orders") {
            axios
              .post("/orders/create", this.editedItem)
              .then(() => {
                this.obtenerOrders();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "orderDetails") {
            console.log("hola2");
            console.log(this.editedItem);
            axios
              .post("/orderDetails", this.editedItem)
              .then(() => {
                this.obtenerOrderDetails();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "telas") {
            axios
              .post("/telas", this.editedItem)
              .then(() => {
                this.obtenerTelas();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "shortNames") {
            axios
              .post("/shortNames", this.editedItem)
              .then(() => {
                this.obtenerShortNames();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "groups") {
            axios
              .post("/groups", this.editedItem)
              .then(() => {
                this.obtenerGroups();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "paintings") {
            axios
              .post("/paintings", this.editedItem)
              .then(() => {
                this.obtenerPaintings();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "categories") {
            axios
              .post("/categories", this.editedItem)
              .then(() => {
                this.obtenerCategories();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "clients") {
            axios
              .post("/clients", this.editedItem)
              .then(() => {
                this.obtenerClients();
              })
              .catch((error) => {
                console.log(error);
              });
          }
          if (this.nombre.id == "products") {
            console.log(this.editedItem);
            axios
              .post("/products", this.editedItem)
              .then(() => {
                this.obtenerProducts();
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
        this.close();
      }
    },
    obtenerUsuarios() {
      axios
        .get("/user/getUsers")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerAlturas() {
      axios
        .get("/alturas")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerInvoices() {
      axios
        .get("/invoice/getAll")
        .then((response) => {
          this.data = response.data.data;
          this.invoicesProducts = response.data.products;
          this.invoicesClients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerGroups() {
      axios
       .get("/groups")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerShortNames() {
      axios
        .get("/shortNames")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerCategories() {
      axios
        .get("/categories")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerComplaints() {
      axios
        .get("/complaints/getAll")
        .then((response) => {
          this.data = response.data.data;
          this.complaintsClient = response.data.client;
          this.complaintsProduct = response.data.product;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerOrderDetails() {
      axios
        .get("/orderDetails")
        .then((response) => {
          this.data = response.data.data;
          this.ordersAll = response.data.orders;
          this.products_array = response.data.products;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerHumanResources() {
      axios
        .get("/human_resource/getAll")
        .then((response) => {
          console.log(response);
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerClients() {
      axios
        .get("/clients")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerProduction() {
      axios
        .get("/daily_production/getAll")
        .then((response) => {
          this.data = response.data.data;
          this.productionProducts = response.data.products;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerOrders() {
      axios
        .get("/orders/getAll")
        .then((response) => {
          this.data = response.data.data;
          this.ordersClients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerTelas() {
      axios
        .get("/telas")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerPaintings() {
      axios
        .get("/paintings")
        .then((response) => {
          this.data = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    obtenerProducts() {
      axios
        .get("/products")
        .then((response) => {
          this.data = response.data.data;
          this.productCategories = response.data.categories;
          this.productShortName = response.data.short_name;
          this.productTela = response.data.telas;
          this.productPintura = response.data.pintura;
          this.productAltura = response.data.altura;
          this.productGroup = response.data.group;
          this.productClient = response.data.client;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //Adjunamos el archivo excel
    submitFile() {
      if (this.$refs.forms.validate()) {
        let formData = new FormData();
        formData.append("file", this.file);
        formData.append("nombre", this.nombre.id);
        formData.forEach((value, key) => {
          console.log(key + value);
        });
        console.log(this.nombre.id);
        this.cargar = true;
        axios
          .post("/uploadFiles", formData)
          .then((resp) => {
            console.log(resp.data);
            if (this.nombre.id == "alturas") this.obtenerAlturas();
            if (this.nombre.id == "categories") this.obtenerCategories();
            if (this.nombre.id == "human_resources")
              this.obtenerHumanResources();
            if (this.nombre.id == "clients") this.obtenerClients();
            if (this.nombre.id == "paintings") this.obtenerPaintings();
            if (this.nombre.id == "products") this.obtenerProducts();
            if (this.nombre.id == "shortNames") this.obtenerShortNames();
            if (this.nombre.id == "telas") this.obtenerTelas();
            if (this.nombre.id == "invoices") this.obtenerInvoices();
            if (this.nombre.id == "groups") this.obtenerGroups();
            if (this.nombre.id == "orders") this.obtenerOrders();
            if (this.nombre.id == "orderDetails") this.obtenerOrderDetails();
            if (this.nombre.id == "production") this.obtenerProduction();
            if (this.nombre.id == "complaints") this.obtenerComplaints();
            if (this.nombre.id == "usuarios") this.obtenerUsuarios();
            this.$toast.success("Carga exitosa");
          })
          .catch((error) => {
            this.$toast.error(error.response.data.errors[0][0]);
          })
          .finally(() => {
            this.cargar = false;
          });
      }
    },

    /*
        Handles a change on the file upload
      */
    handleFileUpload() {
      this.selectData = 1;
      this.file = this.$refs.file.files[0];
    },
    getproductAPI() {
      this.products = [
        {
          name: "Alturas",
          id: "alturas",
        },
        {
          name: "Categorias",
          id: "categories",
        },
        {
          name: "Clients",
          id: "clients",
        },
        {
          name: "Detalle de ordenes",
          id: "orderDetails",
        },
        {
          name: "Facturacion",
          id: "invoices",
        },
        {
          name: "Grupos",
          id: "groups",
        },
        {
          name: "Ordenes",
          id: "orders",
        },
        {
          name: "Pinturas",
          id: "paintings",
        },
        {
          name: "Producción",
          id: "production",
        },
        {
          name: "Productos",
          id: "products",
        },
        {
          name: "Quejas",
          id: "complaints",
        },
        {
          name: "Recursos humanos",
          id: "human_resources",
        },
        {
          name: "Short Names",
          id: "shortNames",
        },
        {
          name: "Telas",
          id: "telas",
        },
      ];
    },
  },
  mounted() {
    this.getproductAPI();
  },
};
</script>